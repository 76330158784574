import type { ComponentName } from '#build/components.dynamic'

const ACTION_COMPONENTS = {
  ForecastReviewDetails: 'ReportsTableActionComponentForecastReviewDetails',
  forecastReviewDetails: 'ReportsTableActionComponentForecastReviewDetails',
}

const [useProvideDynamicCell, useDynamicCell] = createInjectionState(() => {
  // CELL
  const { getComponent: getCellComponent } = useDynamicComponent({
    defaultComponent: 'ReportsTableCell',
  })

  // ACTION
  const { getComponent: _getActionComponent } = useDynamicComponent()
  const getActionComponent = (
    name: keyof typeof ACTION_COMPONENTS | ComponentName | (string & {}),
  ) => {
    const _name = (ACTION_COMPONENTS[name as keyof typeof ACTION_COMPONENTS] ??
      name) as ComponentName
    return _getActionComponent(_name)
  }

  return { getCellComponent, getActionComponent }
})

export { useProvideDynamicCell, useDynamicCell }
